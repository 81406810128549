<template>
  <v-container fluid class="ml-10">
    <br /><br />

    <v-row>
      <v-col cols="12" v-if="payment">
        <h1 class="mr-8">
          <v-btn fab dark small color="#479b25" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          {{ getFormatTitle(payment.createdAt) }}
        </h1>
      </v-col>

      <v-col>
        <br />
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4">
              <h2>Estado de Pago</h2>
              <br />
              <v-form>
                <label for=""><strong>Estado</strong></label>
                <v-select
                  v-model="ipayment.status"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-clipboard-file-outline"
                  label="Estado"
                />

                <label for=""><strong>Método de Pago</strong></label>
                <v-select
                  v-model="ipayment.payment_method_id"
                  :items="payment_methods"
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-clipboard-file-outline"
                  label="Método de Pago"
                  required
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="text-right">
              <v-btn height="40" small color="primary" dark @click="save">
                Validar datos
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "payment-edit",
  watch: {
    payment(value) {
      this.ipayment = Object.assign({}, value);
    },
  },
  data: () => ({
    text: "",
    color: "",
    snackbar: false,
    status: [
      {
        id: 1,
        name: "Pagado",
      },
      {
        id: 2,
        name: "Vencido",
      },
      {
        id: 0,
        name: "En curso",
      },
    ],
    ipayment: {},
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      payment: (state) => state.payment.payment,
      fetchingData: (state) => state.fetchingData,
      // payment_title: (state) => state.payment.payment_title,
      payment_methods: (state) => state.util.payment_methods,
    }),
  },
  methods: {
    ...mapActions(["getPaymentById", "editPaymentById", "fetchPaymentMethods"]),
    getFetch() {
      this.fetchPaymentMethods();
      this.getPaymentById({ id: this.id });
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFormatTitle(f) {
      if (f) {
        const meses = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        let date = new Date(f);
        return meses[date.getMonth()] + " " + date.getFullYear();
      }
      return "";
    },
    save() {
      this.editPaymentById({
        id: this.id,
        payment: this.ipayment,
      })
        .then((response) => {
           this.mensaje(true, "success", response.data.message, 5000);
        })
        .catch((err) => {
          this.mensaje(true, "error", err.errorMessage, 5000);
        });
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style>
</style>